<template>
  <div class="container">
    <h1>Mit Sicherheit</h1>
    <div class="row">
      <p>
        Das Experimentiermodul “Mit Sicherheit” hat Ihnen gezeigt wie in der
        Informatik vorgegangen wird um Nachrichten sicher zu versenden. Dabei
        haben Sie kennen gelernt, welche zwei grundlegenden Ver- und
        Entschlüsselungsmethoden existieren und welche Herausforderungen mit der
        Verschlüsselung von Nachrichten verbunden sind.
      </p>
      <p>
        Eine detaillierte Darstellung Ihrer Bearbeitung finden Sie in den
        Ergebnissen der beiden Aufgaben unten.
      </p>
    </div>

    <div class="row">
      <h2>Aufgabe 1</h2>
      <p>
        Ihre Aufgabe im ersten Teil des Experiments war es, eine mittels
        symmetrischer Verschlüsselung unlesbar gemachte Nachricht, wieder lesbar
        zu machen. Sie haben dabei gesehen, wie einfach es ist eine solche
        Verschlüsselungsmethode zu “knacken” und dass solche Methoden in der
        Praxis oft nicht den gewünschten Sicherheitsaspekt bieten können.
      </p>
      <p>
        Zu Beginn des Beispiels war der verschlüsselte Text dabei der folgende:
      </p>
  </div>

    <div class="row">
      <h2>Aufgabe 2</h2>
      <p>
        In der zweiten Aufgabe des Experiments “Mit Sicherheit” haben Sie die
        Funktionsweise von asymmetrischer Verschlüsselung kennen gelernt. Hier
        haben Sie gesehen, dass eine solche Verschlüsselungsmethode zwar etwas
        komplizierter zu erstellen ist, aber auch mehr Sicherheit beim
        Nachrichtenaustausch bietet. Dabei war es Ihre Aufgabe die öffentlichen
        Schlüssel innerhalb einer Gruppe von Personen so zu verteilen, dass
        diese untereinander sicher kommunizieren können. Unten sehen Sie nochmal die endgültige Verteilung der Schlüssel.
      </p>
    </div>
  </div>
</template>

<script></script>
<style scoped>
.additional-info-italic {
  font-style: italic;
}

.custom-btn,
.custom-btn-secondary {
  float: right;
}

p.sentence {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.word {
  display: flex;
  justify-content: center;
  margin: 10px;
}
.letter {
  display: flex;
  justify-content: center;
  font-size: 30px;
  min-width: 20px;
  padding: 3px;
  border: 2px solid transparent;
  font-family: monospace;
}

.letter.encoded {
  color: red;
}

.letter.decoded {
  color: green;
}

.letter.encoded.replaced, input.replaced {
  background-color: pink;
}

.anzahl {
  text-align: right;
}

.anzahl-head {
  text-align: center;
}
</style>
